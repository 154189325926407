import Cfptb from "../../components/cfp/cfptb";
import {cfpColumns} from "../../components/utils/cfpcolumns";
import React, {useContext, useEffect, useState} from "react";
import {GlobalStateContext} from "../../context/GlobalContextProvider";
import AccessCheck from "../../components/attendance/accessCheck";
import Layout from "../../layouts";
import Hider from "../../components/utils/Hider";
import AddEmployee from "../../components/attendance/addEmployee";
import {API} from "aws-amplify";
import {CfpSearch} from "../../components/cfp/cfpSearch";
import CmSureCancel from "../../components/CmSureCancel";
import {gConfig} from "../../chatai/utils/config";
import {gUtils} from "../../components/utils/gutils";

export default function Employees({location}) {

    const {
        gCompanyEmployee,
        gIotCfp,
        gCfp,
        gCfpfp,
        gCfpCards,
        gStripe,
        setModalTitle,
        setModalContent,
        setCustomModalOpen
    } = useContext(GlobalStateContext)
    const [limitWarning, setLimitWarning] = useState(false)
    const [csvLimitWarning, setCsvLimitWarning] = useState(false)
    const [openHead, setopenHead] = useState(false)
    const [tags, setTags] = useState([])
    const [items, setItems] = useState([])
    useEffect(() => {
        if (gStripe.customer.id) {
            API.get('Attendance', `/tag`, {
                queryStringParameters: {companyID: gStripe.customer.id},
            })
                .then((res) => {
                    setTags(res)
                })
                .catch((err) => console.log(err))

        }
    }, [gStripe.customer.id])
    useEffect(() => {
        setItems(gCompanyEmployee.employees.Items)

    }, [gCompanyEmployee.employees.Items])


    const findSearchList = (items, key) => {

        //      console.log("findSearchList",items,key)

        return items.filter(item => {
            // 确保 employeeName 和 employeeEmail 存在且为字符串
            const employeeName = item.employeeName || '';
            const employeeEmail = item.employeeEmail || '';

            return employeeName.includes(key) || employeeEmail.includes(key);
        });

        /*   return items.filter(item => {

               return (item.employeeName.includes(key) || (item.employeeEmail && item.employeeEmail.includes(key)))
           })*/

    }
    const showSureCancel = async (call) => {
        setModalTitle('')
        setModalContent(
            <CmSureCancel
                title={"ユーザーが削除されます"}
                message={"認証機器とHub3との通信が不安定な場合、ユーザーに紐づいたカード・指紋が同時に削除されない可能性がございます。\n通信状況をご確認のうえで操作ください。"}
                cancelClick={() => {
                    setCustomModalOpen(false)
                }}
                sureClick={() => {
                    setCustomModalOpen(false)
                    if (call) call()
                }}

            />
        )
        setCustomModalOpen(true)
    }
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));


    function processFingers2(values) {
        let list = []
        if (!Array.isArray(values) || values.length === 0) {
            console.error("Values should be a non-empty array");
            return list
        }

        for (let i = 0; i < values.length; i++) {
            list.push({
                deviceID: values[i].deviceID,
                cmd: gConfig.cmdCode.fingerDelete,
                data: gUtils.toCardInfo(values[i].fingerID, '0', values[i].name)
            })

        }
        return list
    }


    function processCards2(items) {
        let list = []
        for (const item of items) {
            let groupedCards = {};
            let ms = gCfpCards.allCards.filter(ite => ite.memberID === item.subUUID);
            if (ms.length > 0) {
                ms.forEach(it2 => {
                    it2.uuids.forEach(it3 => {
                        if (!groupedCards[it3]) {
                            groupedCards[it3] = [];
                        }
                        groupedCards[it3].push(it2);
                    });
                });
            }
            for (const uuid of Object.keys(groupedCards)) {
                try {
                    groupedCards[uuid].map(item => {
                        list.push({
                            deviceID: uuid,
                            cmd: gConfig.cmdCode.cardDelete,
                            data: gUtils.toCardInfo(item.cardID, item.cardType, item.name)
                        })
                    })
                } catch (error) {
                    console.error(`Error deleting items for uuid ${uuid}:`, error);
                }


            }
        }
        return list


    }

    return (


        <>
            <Layout location={location}>
                <AccessCheck access="ユーザー">

                    <CfpSearch callSearch={(e) => {
                        if (e) {
                            setItems(findSearchList(gCompanyEmployee.employees.Items, e))
                        } else {
                            setItems(gCompanyEmployee.employees.Items)
                        }
                    }}/>

                    <Hider show={openHead}>


                        <AddEmployee
                            open={openHead}
                            setOpen={setopenHead}
                            tags={tags}
                            setLimitWarning={setLimitWarning}
                            limitWarning={limitWarning}
                            csvLimitWarning={csvLimitWarning}
                            setCsvLimitWarning={setCsvLimitWarning}
                        />
                    </Hider>

                    <Cfptb
                        callAdd={() => {
                            setopenHead(true)
                        }}
                        isAdd={!openHead}
                        data={items}
                        path={'/employees/employeesInfo'}
                        isBind={false}
                        isBack={false}
                        text={`ユーザー 一覧`}
                        columns={cfpColumns.empList}
                        callDelData={async (items) => {

                            await showSureCancel(async () => {
                                let mmids = items.map(item => item.subUUID);
                                let uuids = gCfp.cfpDevices.map(item => item.deviceUUID);
                                await gCompanyEmployee.delMm(items);
                                gCfpfp.getMemberFingers(uuids.join(','), mmids, async (list) => {
                                    let list1 = processFingers2(list)
                                    let list2 = processCards2(items);
                                    let alllist = [...list1, ...list2]
                                    if (alllist.length > 0) {
                                        for (let i = 0; i < alllist.length; i++) {
                                            const item = alllist[i];
                                            await gIotCfp.sendCmd(item.deviceID, item.cmd, item.data);
                                            if (i < alllist.length - 1) {
                                                await delay(1600);
                                            }
                                        }
                                    }
                                })

                            });


                        }}
                        isRowFilter={gStripe.customer.subUUID}


                    />
                </AccessCheck>

            </Layout>

        </>
    )

}
